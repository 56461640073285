// MainSection3.js
import React from 'react';
import './MainSection.css';
import administracionFinancieraVideo from '../assets/videos/video3.mp4';

const MainSection3 = () => {
  return (
    <section className="main-section"data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
      <div className="section-content">
        <div className="video-container" data-aos="fade-right">
          <video autoPlay loop muted playsInline>
            <source src={administracionFinancieraVideo} type="video/mp4" />
            Tu navegador no soporta la etiqueta de video.
          </video>
        </div>
        <div className="text-container" data-aos="fade-left">
          <h2>Modulo Administración </h2>
          <p>
            Controla y gestiona las finanzas de tu residencia con herramientas precisas y eficientes.
          </p>
          <ul>
            <li>Envio masivo de Cobros por mensualidad</li>
            <li>Control de pagos y cobranzas</li>
            <li>Estadisticas de Ingresos $</li>
            <li>Integración con sistestemas de pagos</li>
            <li>Análisis para toma de desiciones</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default MainSection3;
