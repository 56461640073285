// App.js
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './styles.css';  // Importación de los estilos globales

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



// Importa los componentes comunes
import Header from './components/Header_temp';
import Footer from './components/FooterSection';

// Importa las páginas
import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import ArticlePage from './pages/ArticlePage';
import SobreNosotros from './pages/SobreNosotros';
import TerminosYCondiciones from './pages/TerminosYCondiciones';
import PoliticasDePrivacidad from './pages/PoliticasDePrivacidad';
import ScrollToTop from './components/ScrollToTop';
import MainSection1 from './components/MainSection1';
import TestimonialsSection from './components/TestimonialsSection';
import ContactSection from './components/ContactSection';
// Agrega otros componentes o páginas si es necesario

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing:'ease-in-out',
      once: true,
      mirror:false,// duración de las animaciones en milisegundos
    });
  }, []);

  return (
    <Router>
      <ScrollToTop/>
      <div className="App">
        <Header />
        <Routes>
          {/* Ruta para la página de inicio */}
          <Route path="/" element={<HomePage />}/>
          <Route path="/servicios" element={<MainSection1 />} />
          <Route path="/testimonios" element={<TestimonialsSection />} />
          <Route path="/contacto" element={<ContactSection />} />

           

          {/* Rutas para otras páginas */}
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<ArticlePage />} />
          <Route path="/sobre-nosotros" element={<SobreNosotros />} />
          <Route path="/terminos-y-condiciones" element={<TerminosYCondiciones />} />
          <Route path="/politicas-de-privacidad" element={<PoliticasDePrivacidad />} />

          {/* Ruta "Catch-All" para manejar rutas no definidas */}
          <Route path="*" element={<HomePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
