import React from 'react';
import './FortalezasSection.css';
import { FaLaptop, FaPalette, FaLock, FaBook, FaComments, FaChartBar } from 'react-icons/fa';

const fortalezas = [
  {
    icon: <FaLaptop />,
    title: 'Digitalización',
    text: 'Transforma tus procesos en experiencias digitales eficientes. Di adiós al papeleo y hola a la innovación.',
  },
  {
    icon: <FaPalette />,
    title: 'Personalización',
    text: 'Adapta la plataforma a las necesidades únicas de tu residencia. Tu gestión, a tu manera.',
  },
  {
    icon: <FaLock />,
    title: 'Seguridad de Datos',
    text: 'Protege la información de tus residentes con estándares de seguridad de nivel bancario. Confidencialidad garantizada.',
  },
  {
    icon: <FaBook />,
    title: 'Capacitación y Soporte',
    text: 'Acompañamos a tu equipo en cada paso. Soporte dedicado y formación continua para un uso óptimo.',
  },
  {
    icon: <FaComments />,
    title: 'Mejora de la Comunicación',
    text: 'Fortalece la relación con familiares y cuidadores. Comunicación fluida y transparente al alcance de un clic.',
  },
  {
    icon: <FaChartBar />,
    title: 'Análisis y Reportes Avanzados',
    text: 'Toma decisiones informadas con datos en tiempo real. Visualiza el progreso y mejora continuamente.',
  },
];

const FortalezasSection = () => {
  return (
    <section className="fortalezas-section"data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
      <h2>Nuestras Fortalezas</h2>
      <div className="cards-container">
        {fortalezas.map((item, index) => (
          <div className="card" key={index} data-aos="fade-up" data-aos-delay={index * 100}>
            <div className="icon">{item.icon}</div>
            <h3>{item.title}</h3>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FortalezasSection;
