// NewModulesSection.js
import React from 'react';
import './NewModulesSection.css';

const NewModulesSection = () => {
  return (
    <section className="new-modules-section" id="nuevos-modulos"data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
      <div className="section-container">
        <h2 className="section-title" data-aos="fade-up">
          Impulsa tú Residencia
        </h2>
        <div className="cards-container">
          {/* Tarjeta del Portal de Apoderados */}
          <div className="module-card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
            <div className="card-content">
              <h3 className="card-title">
                Portal de Apoderados
              </h3>
              <p className="card-description">
                En <strong>Soluciones Mayores</strong>, comprendemos la importancia de mantener a las familias informadas y conectadas con sus seres queridos. Por eso, hemos creado el <strong>Portal de Apoderados</strong>, un espacio seguro y personalizado donde los apoderados pueden acceder a información relevante sobre el estado y bienestar de sus familiares en la residencia.
              </p>
              <ul className="features-list">
                <li><strong>Acceso Seguro y Personalizado:</strong> Inicia sesión en una plataforma intuitiva que protege la confidencialidad de la información.</li>
                <li><strong>Información Actualizada del Residente:</strong> Consulta datos sobre la salud, actividades diarias, terapias y progresos de tu familiar.</li>
                <li><strong>Comunicación Directa:</strong> Interactúa con el equipo de cuidado a través de mensajes y recibe notificaciones importantes.</li>
                <li><strong>Documentación Accesible:</strong> Descarga informes, planes de cuidado y autorizaciones en cualquier momento.</li>
              </ul>
            </div>
          </div>
          {/* Tarjeta del Portal del Cuidador */}
          <div className="module-card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
            <div className="card-content">
              <h3 className="card-title">
                Portal del Cuidador
              </h3>
              <p className="card-description">
                Reconocemos el valioso trabajo que realizan los cuidadores en el día a día. Para apoyarlos, presentamos el <strong>Portal del Cuidador</strong>, una herramienta diseñada para facilitar el registro y seguimiento de la información diaria de cada residente, optimizando los procesos y mejorando la atención brindada.
              </p>
              <ul className="features-list">
                <li><strong>Registro Diario Eficiente:</strong> Documenta de manera rápida eventos importantes, como medicación administrada, signos vitales y notas de comportamiento.</li>
                <li><strong>Integración con el Programa Principal:</strong> La información recopilada se sincroniza automáticamente, garantizando que todo el equipo tenga acceso a datos actualizados.</li>
                <li><strong>Acceso a Planes de Cuidado Personalizados:</strong> Consulta las necesidades específicas de cada residente para ofrecer una atención más efectiva.</li>
                <li><strong>Comunicación Interna Mejorada:</strong> Reporta incidencias o comparte observaciones con el equipo multidisciplinario de manera instantánea.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewModulesSection;
