// TestimonialsSection.js
import React from 'react';
import Slider from 'react-slick';
import './TestimonialsSection.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const testimonials = [
  {
    id: 1,
    name: 'Juan Pérez',
    image: '/assets/img/testimonial1.jpg',
    text: 'La plataforma ha revolucionado la forma en que gestionamos nuestra residencia.',
  },
  {
    id: 2,
    name: 'María López',
    image: '/assets/img/testimonial2.jpg',
    text: 'Ahora puedo estar al tanto del estado de mi madre en tiempo real.',
  },
  {
    id: 3,
    name: 'Carlos García',
    image: '/assets/img/testimonial3.jpg',
    text: 'La administración financiera es más sencilla y eficiente.',
  },
  {
    id: 4,
    name: 'Ana Fernández',
    image: '/assets/img/testimonial4.jpg',
    text: 'Excelente soporte y funcionalidades intuitivas.',
  },
];

const TestimonialsSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="testimonials-section" id="testimonios">
      <h2>Testimonios</h2>
      <div className="testimonials-container">
        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div className="testimonial-card" key={testimonial.id}>
              <img src={testimonial.image} alt={testimonial.name} />
              <p>"{testimonial.text}"</p>
              <h4>{testimonial.name}</h4>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default TestimonialsSection;
