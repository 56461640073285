import React from 'react';
import './TerminosYCondiciones.css';

const TerminosYCondiciones = () => {
  return (
    <section className="terminos-y-condiciones">
      <div className="container">
        <h1>Términos y Condiciones</h1>
        <div className="content">
          <h2>1. Aceptación de los Términos</h2>
          <p>Al acceder y utilizar el sitio web de Soluciones Mayores ("nosotros", "nuestro"), usted ("el usuario") acepta estar sujeto a los siguientes términos y condiciones. Si no está de acuerdo con estos términos, le recomendamos no utilizar nuestro sitio web.</p>

          <h2>2. Uso del Sitio Web</h2>
          <p>El usuario se compromete a utilizar este sitio web de manera responsable y conforme a la ley, absteniéndose de cualquier conducta que pueda dañar la imagen, intereses o derechos de Soluciones Mayores o de terceros.</p>

          <h2>3. Propiedad Intelectual</h2>
          <p>Todos los contenidos, diseños, textos, gráficos, logos, iconos, imágenes y software en este sitio web son propiedad de Soluciones Mayores o de terceros que han autorizado su uso, y están protegidos por las leyes de propiedad intelectual. Queda prohibida la reproducción, distribución o modificación no autorizada de dichos contenidos.</p>

          <h2>4. Enlaces a Terceros</h2>
          <p>Este sitio web puede contener enlaces a sitios web de terceros. Soluciones Mayores no es responsable del contenido ni de las políticas de privacidad de dichos sitios. El usuario accede a ellos bajo su propia responsabilidad.</p>

          <h2>5. Limitación de Responsabilidad</h2>
          <p>Soluciones Mayores no garantiza la disponibilidad continua del sitio web ni se responsabiliza por cualquier daño o perjuicio que pudiera derivarse del uso del mismo, incluyendo, pero no limitado a, errores u omisiones en los contenidos, falta de disponibilidad o transmisión de virus.</p>

          <h2>6. Modificaciones</h2>
          <p>Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Las modificaciones entrarán en vigor desde el momento de su publicación en el sitio web. Se recomienda al usuario revisar periódicamente estos términos.</p>

          <h2>7. Ley Aplicable y Jurisdicción</h2>
          <p>Estos términos y condiciones se rigen por las leyes de Chile. Cualquier disputa que surja en relación con el uso del sitio web será sometida a la jurisdicción exclusiva de los tribunales de Santiago.</p>

          <h2>8. Contacto</h2>
          <p>Para cualquier consulta o comentario sobre estos términos y condiciones, puede contactarnos a través de nuestro correo electrónico: info@solucionesmayores.cl</p>
        </div>
      </div>
    </section>
  );
};

export default TerminosYCondiciones;
