// ResidencesSection.js
import React from 'react';
import './ResidencesSection.css';

// Importa los logos de las residencias
import residencia1 from '../assets/logos/residencia1.png';
import residencia2 from '../assets/logos/residencia2.png';
// Añade más logos según sea necesario

const ResidencesSection = () => {
  const logos = [
    { id: 1, src: residencia1, alt: 'Residencia 1' },
    { id: 2, src: residencia2, alt: 'Residencia 2' },
    // Añade más objetos al array para cada logo
  ];

  return (
    <section className="residences-section">
      <div className="container">
        <h2 data-aos="fade-up">Residencias que Confían en Nosotros</h2>
        <div className="logo-slider">
          <div className="slider-track">
            {logos.map((logo) => (
              <div className="slide" key={logo.id}>
                <img src={logo.src} alt={logo.alt} />
              </div>
            ))}
            {/* Repite los logos para crear un efecto de carrusel infinito */}
            {logos.map((logo) => (
              <div className="slide" key={`${logo.id}-copy`}>
                <img src={logo.src} alt={logo.alt} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResidencesSection;
