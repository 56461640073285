import articulo1Image from '../assets/img/articulo1.jpg';
import articulo2Image from '../assets/img/articulo2.jpg';
import articulo3Image from '../assets/img/articulo3.jpg';

const articles = [
  {
    title: '¿Cómo identificar el Alzheimer y cuál es la importancia de la prevención?',
    date: '06 de octubre de 2024',
    excerpt: '¿Qué dicen los expertos?...',
    image: articulo1Image,
    slug: 'innovando-en-el-cuidado-de-adultos-mayores',
    source: 'https://www.cnnchile.com/lodijeronencnn/como-identificar-alzheimer-cual-importancia-prevencion_20241006/',
    content: [
      'En entrevista con CNN Chile, la neuróloga Nicole Rogers, de la Clínica Universidad de los Andes, conversó sobre cómo prevenir esta enfermedad.El Alzheimer, una de las formas más comunes de demencia, afecta a millones de personas en todo el mundo, generando un impacto profundo tanto en los pacientes como en sus familias.A medida que la población global, la urgencia por desarrollar estrategias de prevención se vuelve más crítica, ya que intervenciones tempranas pueden reducir significativamente el riesgo de desarrollar esta enfermedad.',
      
    ]
  },
  {
    title: 'Envejecimiento y salud',
    date: '10 de octubre de 2024',
    excerpt: 'Todos los países se enfrentan a retos importantes...',
    image: articulo2Image,
    slug: 'consejos-para-mejorar-la-gestion-en-residencias',
    source: 'https://www.who.int/es/news-room/fact-sheets/detail/ageing-and-health',
    content: [
      'El envejecimiento de la población es un tema importante...',
      'Con el envejecimiento, es vital garantizar sistemas de salud...'
    ]
  },
  {
    title: 'El secreto para que los adultos mayores vivan más tiempo y con mejor salud',
    date: '26 de marzo de 2024',
    excerpt: 'Todavía existe el pensamiento de que el envejecimiento...',
    image: articulo3Image,
    slug: 'mejores-practicas-para-atencion-al-adulto-mayor',
    source: 'https://www.latercera.com/tendencias/noticia/el-secreto-para-que-los-adultos-mayores-vivan-mas-tiempo-y-con-mejor-salud/G4WKGKEUHNHPREZFSMNF3NYVJU/',
    content: [
      'En este artículo discutimos cómo una vida activa puede...',
      'Las investigaciones muestran que la actividad física regular...'
    ]
  }
];

export default articles;
