// FreeTrialSection.js
import React from 'react';
import './FreeTrialSection.css';

const FreeTrialSection = () => {
  return (
    <section className="free-trial-section" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
      <div className="container">
        <h2>Experimenta las Funcionalidades</h2>
        <p>
          Experimenta funcionalidades de nuestra plataforma sin costo alguno, por un tiempo de prueba. Optimiza la gestión de tu residencia y descubre cómo podemos ayudarte a mejorar.
        </p>
        <ul className="benefits-list">
          <li>Acceso a características escenciales</li>
          <li>Soporte y capacitación personalizada</li>
          <li>Sin necesidad de tarjeta de crédito</li>
          <li>Cancelación en cualquier momento</li>
          <li>Adaptable a cualquier tamaño de Residencias</li>
        </ul>
        <a href="/registro" className="btn-trial">Comenzar Prueba Gratuita</a>
      </div>
    </section>
  );
};

export default FreeTrialSection;
