// StatsSection.js
import React, { useEffect } from 'react';
import './StatsSection.css';

const stats = [
  { id: 1, label: 'Residentes', value: 107 },
  { id: 2, label: 'Apoderados', value: 214 },
  { id: 3, label: 'Profesionales', value: 65 },
  { id: 4, label: 'Residencias', value: 6 },
];

const StatsSection = () => {
    useEffect(() => {
      const counters = document.querySelectorAll('.stat-number');
  
      const animateCounters = () => {
        counters.forEach(counter => {
          counter.innerText = '0';
          const updateCounter = () => {
            const target = +counter.getAttribute('data-target');
            const count = +counter.innerText;
            const increment = target / 200;
  
            if (count < target) {
              counter.innerText = `${Math.ceil(count + increment)}`;
              setTimeout(updateCounter, 10);
            } else {
              counter.innerText = target;
            }
          };
          updateCounter();
        });
      };
  
      const statsSection = document.getElementById('contador');
      statsSection.addEventListener('mouseover', animateCounters);
  
      return () => {
        statsSection.removeEventListener('mouseover', animateCounters);
      };
    }, []);
  
    return (
      <section id="contador" className="stats-section"data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
        <h2>Actualmente nos Prefieren</h2>
        <div className="stats-container">
          {stats.map(stat => (
            <div className="stat" key={stat.id}>
              <h3 className="stat-number" data-target={stat.value}>0</h3>
              <p>{stat.label}</p>
            </div>
          ))}
        </div>
        <button className="btn-stats">Prueba Gratis</button>
      </section>
    );
  };
  
  export default StatsSection;