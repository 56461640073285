import React from 'react';
import './PoliticasDePrivacidad.css';

const PoliticasDePrivacidad = () => {
  return (
    <section className="politicas-de-privacidad">
      <div className="container">
        <h1>Política de Privacidad</h1>
        <div className="content">
          <h2>1. Información que Recopilamos</h2>
          <p>Recopilamos información personal cuando se comunica con nosotros a través de formularios de contacto. Esta información incluye su nombre, dirección de correo electrónico, número de teléfono y cualquier otra información que decida proporcionarnos.</p>

          <h2>2. Uso de la Información</h2>
          <p>Utilizamos la información recopilada para proporcionar y mejorar nuestros servicios, responder a sus consultas, enviar comunicaciones comerciales si ha dado su consentimiento, y analizar el uso del sitio web.</p>

          <h2>3. Protección de la Información</h2>
          <p>Implementamos medidas de seguridad técnicas y organizativas para proteger sus datos personales contra el acceso no autorizado, pérdida o destrucción.</p>

          <h2>4. Compartir Información con Terceros</h2>
          <p>No vendemos, alquilamos ni divulgamos sus datos personales a terceros, excepto cuando sea necesario para proporcionar nuestros servicios o cuando estemos legalmente obligados a hacerlo.</p>

          <h2>5. Cookies y Tecnologías Similares</h2>
          <p>Utilizamos cookies para mejorar la funcionalidad del sitio web y su experiencia de navegación. Puede configurar su navegador para rechazar cookies, pero esto puede limitar algunas funcionalidades del sitio.</p>

          <h2>6. Sus Derechos</h2>
          <p>Usted tiene derecho a acceder, rectificar o eliminar sus datos personales, así como a oponerse al procesamiento de los mismos. Para ejercer estos derechos, puede contactarnos en info@solucionesmayores.cl</p>

          <h2>7. Cambios en la Política de Privacidad</h2>
          <p>Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Los cambios serán efectivos desde el momento de su publicación en el sitio web.</p>

          <h2>8. Contacto</h2>
          <p>Si tiene preguntas o inquietudes sobre nuestra política de privacidad, puede contactarnos en info@solucionesmayores.cl o al teléfono +56 9 1234 5678.</p>
        </div>
      </div>
    </section>
  );
};

export default PoliticasDePrivacidad;
