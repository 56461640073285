import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll'; // Usamos react-scroll para la navegación suave
import { Link, useNavigate } from 'react-router-dom'; // Para enlaces a otras páginas
import './Header.css';
import logo from '../assets/img/logo.png';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const navigate = useNavigate();

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (isMobileMenuOpen) {
      setOpenDropdown(null);
    }
  };

  const handleDropdownToggle = (dropdownName) => {
    if (openDropdown === dropdownName) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(dropdownName);
    }
  };

  // Detectar la dirección del scroll
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        // Scrolling hacia abajo
        setIsHeaderVisible(false);
      } else {
        // Scrolling hacia arriba
        setIsHeaderVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const handleInicioClick = () => {
    // Navega a la página de inicio si no estás en ella
    navigate('/');
    // Luego desplaza hacia la parte superior de la página
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 200); // Tiempo para dar oportunidad a React Router de navegar primero
  };

  return (
    <header className={`header ${isHeaderVisible ? '' : 'header-hidden'}`}>
      <div className="menu-container">
        <div className="logo-container">
          <Link to="/" onClick={handleInicioClick}>
            <img src={logo} alt="Soluciones Mayores Logo" className="logo-img" />
          </Link>
          <div className="logo-text">Soluciones Mayores</div>
        </div>

        {/* Icono de Menú Hamburguesa para Móviles */}
        <div className="mobile-menu-icon" onClick={handleMobileMenuToggle}>
          <div className={`hamburger ${isMobileMenuOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <nav id="navbar" className={`${isMobileMenuOpen ? 'mobile-open' : ''}`}>
          <ul className="nav-links">
            <li>
              <Link to="/" onClick={handleInicioClick}>Inicio</Link>
            </li>
            <li className={`dropdown-container ${openDropdown === 'proyectos' ? 'open' : ''}`}>
              <Link to="#" onClick={() => handleDropdownToggle('proyectos')}>Proyectos</Link>
              <ul className="dropdown">
                <li><Link to="/proyecto-geras">Proyecto Geras</Link></li>
              </ul>
            </li>
            <li>
              <ScrollLink to="servicios" smooth={true} duration={500} offset={-70}>Servicios</ScrollLink>
            </li>
            <li>
              <ScrollLink to="testimonios" smooth={true} duration={500} offset={-70}>Testimonios</ScrollLink>
            </li>
            <li>
              <ScrollLink to="contacto" smooth={true} duration={500} offset={-70}>Contacto</ScrollLink>
            </li>
            <li className={`dropdown-container ${openDropdown === 'nosotros' ? 'open' : ''}`}>
              <Link to="#" onClick={() => handleDropdownToggle('nosotros')}>Nosotros</Link>
              <ul className="dropdown">
                <li><Link to="/sobre-nosotros">Sobre Nosotros</Link></li>
                <li><Link to="/blog">Blogs</Link></li>
              </ul>
            </li>
            <li>
              <Link to="/iniciar-sesion" className="btn-iniciar-menu">Iniciar Sesión</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
