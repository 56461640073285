// HomePage.js
import React from 'react';

// Importa las secciones de la página principal
import WelcomeSection from '../components/WelcomeSection';
import HowItWorksSection from '../components/HowItWorksSection';
import HoteleriaSection from '../components/MainSection1';
import PerfilClinicoSection from '../components/MainSection2';
import AdministracionFinancieraSection from '../components/MainSection3';
import NewModulesSection from '../components/NewModulesSection';
import StatsSection from '../components/StatsSection';
import FreeTrialSection from '../components/FreeTrialSection';
import TestimonialsSection from '../components/TestimonialsSection';
import FortalezasSection from '../components/FortalezasSection';
import ContactSection from '../components/ContactSection';
import ResidencesSection from '../components/ResidencesSection';


const HomePage = () => {
  return (
    <div>
      <WelcomeSection />
      <HowItWorksSection />
      <HoteleriaSection />
      <PerfilClinicoSection />
      <AdministracionFinancieraSection />
      <NewModulesSection />
      <StatsSection />
      <FreeTrialSection />
      <TestimonialsSection />
      <FortalezasSection />
      <ResidencesSection/>
      <ContactSection />
    </div>
  );
};

export default HomePage;
