import React, { useState } from 'react';
import './ContactSection.css';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    mensaje: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Enviar los datos del formulario al backend
    fetch('http://localhost:5000/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Mensaje enviado:', data);
        alert('Mensaje enviado con éxito');
        setFormData({ nombre: '', email: '', telefono: '', mensaje: '' }); // Limpiar el formulario
      })
      .catch((error) => {
        console.error('Error al enviar el mensaje:', error);
        alert('Hubo un error al enviar el mensaje. Intenta nuevamente.');
      });
  };

  return (
    <section id="contacto"className="contact-section">
      <div className="contact-content">
        <div className="text-container">
          <h2>Contacta con Nosotros</h2>
          <p>Estamos aquí para ayudarte a mejorar la gestión de tu residencia.</p>
          <p>Email: contacto@solucionesmayores.cl</p>
          <p>Teléfono: +56 9 83268859</p>
          <p>“Cuidar de los adultos mayores es cuidar de nuestro propio futuro.”</p>
        </div>
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="nombre"
              placeholder="Nombre"
              value={formData.nombre}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="telefono"
              placeholder="Teléfono"
              value={formData.telefono}
              onChange={handleChange}
              required
            />
            <textarea
              name="mensaje"
              placeholder="Mensaje"
              value={formData.mensaje}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit" className="btn-enviar">Enviar Mensaje</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
