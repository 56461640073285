// BlogPage.js
import React from 'react';
import { Link } from 'react-router-dom'; // Importamos Link de react-router-dom
import './BlogPage.css';
import articles from '../data/articlesData'; // Datos de los artículos

const BlogPage = () => {
  return (
    <div className="blog-page">
      <header className="blog-header">
        <h1>Conectando Historias, Inspirando Cambios</h1>
        <p>
          Explora nuestras novedades y consejos para transformar el cuidado de los adultos mayores.
          Juntos, hacemos la diferencia.
        </p>
      </header>
      <div className="blog-container">
        <aside className="sidebar">
          <div className="widget">
            {/* Puedes añadir contenido adicional aquí */}
          </div>
          <div className="widget">
            <h4>Artículos Recientes</h4>
            <ul>
              {articles.slice(0, 5).map((article, index) => (
                <li key={index}>
                  <Link to={`/blog/${article.slug}`}>{article.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="widget">
            <h4>Suscríbete</h4>
            <p>Recibe las últimas noticias y actualizaciones directamente en tu correo.</p>
            <form className="subscribe-form">
              <input type="email" placeholder="Tu correo electrónico" required />
              <button type="submit">Suscribirse</button>
            </form>
          </div>
        </aside>
        <main className="articles-list">
          {articles.map((article, index) => (
            <div
              className="article-card"
              key={index}
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              {article.image ? (
                <img src={article.image} alt={article.title} className="article-image" />
              ) : (
                <div className="article-placeholder-image">
                  <span>No Image</span>
                </div>
              )}
              <div className="article-details">
                <h3>{article.title}</h3>
                <p className="article-date">{article.date}</p>
                <p className="article-excerpt">{article.excerpt}</p>
                <Link to={`/blog/${article.slug}`} className="read-more">
                  Leer más
                </Link>
                {article.source && (
                  <a
                    href={article.source}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="source-link"
                  >
                    Fuente
                  </a>
                )}
              </div>
            </div>
          ))}
        </main>
      </div>
    </div>
  );
};

export default BlogPage;
