// MainSection2.js
import React from 'react';
import './MainSection.css';
import perfilClinicoVideo from '../assets/videos/video2.mp4';

const MainSection2 = () => {
  return (
    <section className="main-section"data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
      <div className="section-content">
        <div className="text-container" data-aos="fade-right">
          <h2>Módulo de Perfil Clínico</h2>
          <p>
            Registra y monitorea los aspectos clínicos de tus residentes de forma sencilla y segura.
          </p>
          <ul>
            <li>Historial médico completo (ficha clinica)</li>
            <li>Control de medicación y tratamientos</li>
            <li>Alertas y recordatorios</li>
            <li>Acceso para cuidadores y profesionales</li>
            <li>Confidencialidad y seguridad de datos</li>
          </ul>
        </div>
        <div className="video-container" data-aos="fade-left">
          <video autoPlay loop muted playsInline>
            <source src={perfilClinicoVideo} type="video/mp4" />
            Tu navegador no soporta la etiqueta de video.
          </video>
        </div>
      </div>
    </section>
  );
};

export default MainSection2;
