// SobreNosotros.js
import React from 'react';
import './SobreNosotros.css';

const SobreNosotros = () => {
  return (
    <div className="sobre-nosotros">
      <h1>Nuestra Historia, Nuestra Misión</h1>
      <p>
        En <strong>Soluciones Mayores</strong>, nacimos con la convicción de que la tecnología puede mejorar significativamente la calidad de vida de los adultos mayores y facilitar el trabajo de quienes los cuidan. Inspirados por nuestras propias experiencias con seres queridos, nos propusimos crear herramientas que marcaran una diferencia real en el sector geriátrico.
      </p>
      <p>
        Nuestro equipo multidisciplinario trabaja con pasión y dedicación para ofrecer soluciones innovadoras que aborden los desafíos actuales de las residencias y centros de cuidado.
      </p>
      <div className="valores">
        <h2>Nuestros Valores</h2>
        <ul>
          <li><strong>Compromiso:</strong> Dedicados al bienestar de los adultos mayores y al éxito de nuestros clientes.</li>
          <li><strong>Innovación:</strong> Creemos en el poder transformador de la tecnología y buscamos constantemente mejorar.</li>
          <li><strong>Empatía:</strong> Entendemos las necesidades y desafíos del sector, ofreciendo soluciones a medida.</li>
          <li><strong>Integridad:</strong> Operamos con transparencia y ética en todo lo que hacemos.</li>
        </ul>
      </div>
      <div className="equipo">
        <h2>Conoce a Nuestro Equipo</h2>
        {/* Aquí puedes agregar imágenes y descripciones del equipo */}
      </div>
    </div>
  );
};

export default SobreNosotros;
