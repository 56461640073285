import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import articles from '../data/articlesData';
import './ArticlePage.css';

const ArticlePage = () => {
  const { slug } = useParams();
  const article = articles.find((article) => article.slug === slug);

  useEffect(() => {
    window.scrollTo(0, 0); // Asegura que la página se desplaza al inicio al cargar
  }, []);

  if (!article) {
    return (
      <div className="article-page">
        <div className="not-found">Artículo no encontrado</div>
      </div>
    );
  }

  return (
    <div className="article-page">
      <div
        className="back-button-container"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        {/* Botón para volver al blog */}
        <Link to="/blog" className="back-button">
          &larr; Volver al Blog
        </Link>
      </div>
      <header
        className="article-header"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <h1>{article.title}</h1>
        <p className="article-date">{article.date}</p>
      </header>
      {article.image && (
        <img
          src={article.image}
          alt={article.title}
          className="article-image"
          data-aos="fade-up"
          data-aos-duration="1000"
        />
      )}
      <div
        className="article-content"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        {/* Verifica si el artículo tiene contenido y lo muestra */}
        {article.content ? (
          <div>{article.content.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}</div>
        ) : (
          <p>El contenido del artículo no está disponible.</p>
        )}
        {article.source && (
          <p>
            <a
              href={article.source}
              target="_blank"
              rel="noopener noreferrer"
              className="source-link"
            >
              Fuente de Información
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default ArticlePage;
