// HowItWorksSection.js
import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import './HowItWorksSection.css';

const HowItWorksSection = () => {
  return (
    <section className="how-it-works-section" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
      <div className="how-it-works-content">
        <h2>
          <ReactTypingEffect
            text={['Simplifica la Gestión de Residencias',
                    'Automatiza tus Procesos Adminsitrativos',
                    'Enfócate en el Cuidado de tus Residentes'
            ]}
            speed={50}
            eraseSpeed={30}
            typingDelay={500}
            eraseDelay={2000}
            
          />
        </h2>
        <p>
          En Soluciones Mayores, ponemos al adulto mayor en el centro de todo lo que hacemos. Por eso, hemos desarrollado Geras, una plataforma innovadora diseñada para transformar la gestión y administración de las Residencias de Adulto Mayor (ELEAM) en todo el país.
          Geras aborda los desafíos cotidianos de administrar una residencia al simplificar procesos administrativos y recopilar de manera integral la información de cada residente. Al automatizar tareas rutinarias, liberamos tiempo valioso para que tu equipo pueda enfocarse en lo más importante: brindar una atención de calidad y personalizada a los adultos mayores.
        </p>
        <p>
          A continuación, descubre cómo Geras puede revolucionar la operación de tu residencia y mejorar la calidad de vida de tus residentes.
        </p>
      </div>
    </section>
  );
};

export default HowItWorksSection;
